import { Networks } from "./blockchain";

const AVAX_MAINNET = {
    DAO_ADDRESS: "0xBB23475b4b608ca398aBabF16764Cf28784C0cd7",
    MEMO_ADDRESS: "0xEA24c2217b4c69a9c7B4689D3FBcD538D114A6b1", //0x4c8626C1Fa7bFdDC77B7b3a3686b5056CFb3634f
    TIME_ADDRESS: "0xE57FCE5e76A5653d6bD2017c7441FB88961cb841",
    MIM_ADDRESS: "0x2a49D5a46511f52D41d63c2bE6C4c7edfB5d7b77",
    STAKING_ADDRESS: "0xaf64b5eFcC4aC15a70984a81cF793958Ff6eFF99",
    STAKING_HELPER_ADDRESS: "0xF1556108aB50DA6689491688151C3000C22A4284",
    TIME_BONDING_CALC_ADDRESS: "0x19E3Bc11cFFfF12db4ca9BdE21AeBa910b4e4520",
    TREASURY_ADDRESS: "0x174F43cA6e2Ec98a414021D8E354464aC6E6D9FC",
    ZAPIN_ADDRESS: "0x1F275a6BF0018D2B3AB0eA7C72de2B0121deC884", //"0xb98007C04f475022bE681a890512518052CE6104",
    WMEMO_ADDRESS: "0x71BeF341A7f872965d0A078119638e49B7384b62",
};

export const getAddresses = (networkID: number) => {
    if (networkID === Networks.AVAX) return AVAX_MAINNET;
    throw Error("Network don't support");
};
