export const cubesImage = `
                                       ,,
                                  .,,,,,,,,,.
                              ,,,,,,,,,,,,,,,,,,,
                         .,,,,,,,,,,,,,,,,,,,,,,,,,,,,
                     ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
                 ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
             ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,.
            ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,****
            ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,*********
            ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,*************
            ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,*****************
            ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,**********************
            ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,**************************
            ,,,,,,,,,,,,,,,,,,,,,,,,,,,****************************
            ,,,,,,,,,,,,,,,,,,,,,,,,,,,****************************
            ,,,,,,,,,,,,,,,,,,,,,,,,,,,****************************
            ,,,,,,,,,,,,,,,,,,,,,,,,,,,****************************
            ,,,,,,,,,,,,,,,,,,,,,,,,,,,****************************
            ,,,,,,,,,,,,,,,,,,,,,,,,,,,****************************
             ,,,,,,,,,,,,,,,,,,,,,,,,,,***************************
                 .,,,,,,,,,,,,,,,,,,,,,**********************,
                      ,,,,,,,,,,,,,,,,,******************.
                          ,,,,,,,,,,,,,***************.
                      ,,.      ,,,,,,,,**********       ,,
                  ,,.        *&@@@@(,,,****/&@@@@#          .,,
              ,,         ,@@@@@@@@@@@@@%@@@@@@@@@@@@@@           ,,
         ,,.         &@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@&           .,,
    .,,         ,&@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@(          .,.
 ,,         #@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@           ,,
    ,,.        /@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@/           ,,.
        ,,          @@@@@@@@@@@@@@@@@#((((&@@@@@@@@@@@@@@@@           .,,
            ,,,         (@@@@@@@&(((((((((((((%@@@@@@@@,          ,,.
                .,,         *(((((((((((((((((((((*           ,,
                     ,,,,,,,,,,,*/((((((((((((*,,,,,,,,. .,,
                   .,,,,,,,,,,,,,,,,,*(((/,,,,,,,,,,,,,,,,,,
               ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
            ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,**
            ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,******
            ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,***********
            ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,***************
            ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,*******************
            ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,************************
            ,,,,,,,,,,,,,,,,,,,,,,,,,,,****************************
            ,,,,,,,,,,,,,,,,,,,,,,,,,,,****************************
            ,,,,,,,,,,,,,,,,,,,,,,,,,,,****************************
            ,,,,,,,,,,,,,,,,,,,,,,,,,,,****************************
            ,,,,,,,,,,,,,,,,,,,,,,,,,,,****************************
            ,,,,,,,,,,,,,,,,,,,,,,,,,,,****************************
            ,,,,,,,,,,,,,,,,,,,,,,,,,,,****************************
               .,,,,,,,,,,,,,,,,,,,,,,,*************************
                   .,,,,,,,,,,,,,,,,,,,********************.
                        ,,,,,,,,,,,,,,,****************
                            .,,,,,,,,,,************
                                 ,,,,,,*******,
                                     ,,***
`;
